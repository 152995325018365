import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/HomepageResponsive.css';
import schema from './validationSchema';
import HomepageSection from './HomepageSection/HomepageSection';
import AppDetails from './AppDetails/AppDetails';
import SelectorMain from '../ModalSelectors/SelectorMain';
import ServicesGuide from '../../routes/servicesGuide/ServicesGuide';
import HomepagePcHeader from './HomepagePcHeader';
import PcHomeForm from '../PcHomeForm/PcHomeForm';
import HowGomechWorks from './HowGomechWorksPC/HowGomechWorks';
import NewFaqAccordion from '../NewFAQ/NewFaqAccordion';
import Link from '../Link';
import PcHomepageNavigationBar from '../PcHomepageNavigationBar/PcHomepageNavigationBar';
import { fetchWorkshopsNearYou } from '../../_services/api/fetchWorkshopsNearYou';
import { HomepageSections } from '../../constants';
import BottomSlider from '../InsuranceServicesHelpers/BottomSlider/BottomSlider';
import FooterPage from '../FooterPage/FooterPage';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import { setActiveServiceType } from '../../actions/serviceType';
import history from '../../history';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import Toast from '../Toast/Toast';
import Global from '../../global';
import Popup from '../EnquiryPopup/Popup';
import DisplayWrapper from '../../_wrappers/DisplayWrapper/DisplayWrapper';
import RatingReviews from './RatingReviews/RatingReviews';
import MonsoonService from './MonsoonService/MonsoonService';
import autoUtmHistory from '../../_services/autoUtmHistory';
import { tapCheckPriceGtmEvent, viewItemListGtmEvent , midBannerClick, tapMilesEntry, tapWarrantyEntry, viewWarrantyPageEvent, clickCategoryBtnEvent, retailBannerClickEvent} from '../../gtm/gtmServices';
import PricingTable from '../../components/PricingTable/PricingTable';
import TopContent from './TopContent';
import CarouselSkeleton from '../Skeleton/CarouselSkeleton';
import CarouselContent from '../CarouselContent/CarouselContent';
import WorkShopMapPopup from './WorkshopMapPopup/workshopMapPopup';
import UserReviews from '../UserReviews/UserReviews';
import GlideCarousel from '../GlideCarousel/GlideCarousel';
import { getCityWiseRegions } from '../../_services/api/getCityWiseRegions';
import { setRegionsOfCity } from '../../actions/regionsAction';
import { getServicesDetails } from '../../_services/api/getServicesDetails';
import { setServicesDetails } from '../../actions/setServicesDetails';
import { getAllServiceGuide } from '../../_services/api/getAllServiceGuide';
import { setServiceGuide } from '../../actions/serviceGuide';
import { additionalServiceApi } from '../../_services/api/additionalServiceApi';
import { getHomepageBannerLvl2 } from '../../_services/api/getHomepageBannerLvl2';
import { getHowGomechWorks } from '../../_services/api/getHowGomechWorks';
import { setHowGomechWorks } from '../../actions/setHowGomechWorks';
import MonsoonServiceCarousel from './MonsoonService/MonsoonServiceCarousel';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import GoGuranteeSection from './GoGurantee/GoGuranteeSection';
// import InusuranceProgram from './InsuranceProgramPop/InsuranceProgram';
// import GomechBenefitsPC from './GomechBenefitsPC/GomechBenefitsPC';
// import { getBrandName, getFuelName, getModelName } from '../../_services/carDetailGetters';
// import HideOnMobile from '../../_wrappers/HideOnMobile';
// import GoMechanicBenefits from './GoMechanicBenifits/GoMechanicBenifits';
// import {fetchAllBrands} from '../../_services/api/fetchAllBrands';
// import {setAllBrands} from '../../actions/setAllBrands';
// import HideOnMobileTab from '../../_wrappers/HideOnMobileTab';
// import ReviewsCarousel from '../ReviewsCarousel/ReviewsCarousel';
// import WorkShopNearYou from './WorkShopNearYou';
// import LuxeService from './LuxeService';
// import BeatThePrice from './BeatThePrice/BeatThePrice';
// import { createLead } from '../../_services/api/leads';
// import { setLeadAction } from '../../actions/lead';
// import ServiceBuddyCarousel from '../ServiceBuddyCarousel/ServiceBuddyCarousel';
// import { cityIdsForMandatoryPhoneNum } from '../../utilities';
// import setUserCar from '../../actions/cardetails';
// import ServiceBuddyReviews from '../ServiceBuddyCarousel/ServiceBuddyReviews';
// import { getServiceBuddyApi } from '../../_services/api/getServiceBuddyApi';
// import { serviceBuddyAction } from '../../actions/serviceBuddyAction';
// import { getBrandWiseModels } from '../../_services/api/getBrandWiseModels';
// import { setBrandWiseModels } from '../../actions/setBrandWiseModals';
// import { getCategoryList } from '../../_services/api/getCategoryList';
// import { setCategoryList } from '../../actions/category';
// import { setCityWiseRegions } from '../../actions/setCityWiseRegions';
// import { getLuxuryBrands } from '../../_services/api/getLuxuryBrands';
// import { setLuxuryBrands } from '../../actions/setLuxuryBrands';
// import { getMilesData } from '../../_services/api/getMilesData';
// import { setMilesData } from '../../actions/setMilesData';
// import { getOffersPage } from '../../_services/api/getOffersPage';
// import { setOffersPage } from '../../actions/setOffersPage';
// import { getPolicy } from '../../_services/api/getPolicy';
// import {setPolicy } from '../../actions/setPolicy';
// import { getPopularBrands } from '../../_services/api/getPopularBrands';
// import { setPopularBrands } from '../../actions/setPopularBrands';
// import { getReviewPageData } from '../../_services/api/getReviewPageData';
// import { setReviewPageData } from '../../actions/setReviewPageData';
// import { getSegmentList } from '../../_services/api/getSegmentList';
// import { setSegmentList } from '../../actions/setSegmentList';
// import { getTableData } from '../../_services/api/getTableData';
// import { setTableData } from '../../actions/setTableData';
// import { getPopularServiceV2 } from '../../_services/api/getPopularServiceV2';
// import { setPopularServiceV2 } from '../../actions/setPopularServiceV2';
// import { getGomechanicBenefits } from '../../_services/api/getGomechanicBenefits';
// import { setGomechanicBenefits } from '../../actions/setGomechanicBenefits';
// import { getWarrantyDetailsData } from '../../_services/api/getWarrantyDetailsData';
// import { warrantyDetails } from '../../actions/warrantyDetails';
// import { getObdDetailsData } from '../../_services/api/getObdDetailsData';
// import { obdDetails } from '../../actions/obdDetails';

const HomePage = (props) => {
  useStyles(s);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [mobile, setMobile] = useState('');
  const [formValid, SetFormValid] = useState(false);
  const [mobileChanged, setMobileChanged] = useState(false);
  const [workshopData, setWorkshopData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [allBrandsData, setAllBrandsData] = useState([]);
  const [carPartsSection , setarPartsSection] = useState([]);
  const [guaranteeData, setGuaranteeData] = useState([]);

  const [carSelectorShow, SetCarSelectorShow] = useState(false);

  const ApiManager = new Global();

  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1000,
  });

  const [showPopup, setShowPopup] = useState(false);

  const [showInsurancePop, setShowInsurancePop] = useState(false);
  const [load, setLoad] = useState(false);
  const [bannerLvl2, setBannerLvl2] = useState([]);

  const { displayPossibleSctions } = props;
  const { displayConfigurations } = props;
  const [showWorkshopPopup, setShowWorkshopPopup] = useState(false); 
  
  const hidePopup=() => {
    setShowWorkshopPopup(false);
    const z = document.getElementsByTagName('body');
    z[0].style.overflow = 'scroll';
  }

  const hideScroll = () => {
    const x = document.getElementsByTagName('body');
    x[0].style.overflow = 'hidden';
  };

  schema
    .isValid({ mobile })
    .then((res) => SetFormValid(res))
    .catch(console.log);

  const handleMobileChange = (event) => {
    if(props.isMalaysiaRequest) {
      if (isNaN(event.target.value) || event.target.value.length > 11) {
        return false;
      }
    } else {
      if (isNaN(event.target.value) || event.target.value.length > 10) {
        return false;
      }
    } 
    setMobileChanged(true);
    setMobile(event.target.value);
  };

  useEffect(() => {
    // !props.allBrands?.length && fetchAllBrands().subscribe(res => {
    //   props.action(setAllBrands(res?.data))
    // }, (error) => {
    //   console.log(error);
    // })
    
    // !props.brandWiseModels?.length && getBrandWiseModels()
    // .subscribe((res) => {
    //   if(res.data) {
    //     props.action(setBrandWiseModels(res.data));
    //   }
    // }, (err) => {
    //   console.error(err);
    // })

    // !props.categoryList?.length && getCategoryList()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setCategoryList(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !props.luxuryBrands?.length && getLuxuryBrands()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setLuxuryBrands(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !Object.keys(props.milesProductData ?? {}).length && getMilesData()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setMilesData(res?.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !Object.keys(props.offersPage ?? {}).length &&getOffersPage()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setOffersPage(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !Object.keys(props.warrantyDetails ?? {}).length && !props.isMalaysiaRequest && getWarrantyDetailsData()
    // .subscribe(res => {
    //   if(res.data) {
    //     props.action(warrantyDetails(res?.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !Object.keys(props.obdDetails ?? {}).length && !props.isMalaysiaRequest && getObdDetailsData()
    // .subscribe(res => {
    //   if(res.data) {
    //     props.action(obdDetails(res?.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !Object.keys(props.policy ?? {}).length && getPolicy()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setPolicy(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !props.popularBrands?.length && getPopularBrands()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setPopularBrands(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !Object.keys(props.reviewPageData ?? {}).length && getReviewPageData()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setReviewPageData(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !props.segmentList?.length && getSegmentList()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setSegmentList(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    // !props.tableData?.length && getTableData()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setTableData(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    !Object.keys(props.servicesDetails ?? {}).length && getServicesDetails()
    .subscribe(res => {
      if(res.data){
        props.action(setServicesDetails(res.data));
      }
    }, err => {
      console.log(err);
    })

    // !Object.keys(props.home?.popularServiceV2 ?? {}).length && getPopularServiceV2()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setPopularServiceV2(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

    !(Object.keys(props.home?.serviceGuide ?? {}).length > 3) && getAllServiceGuide()
    .subscribe(res => {
      if(res.data){
        props.action(setServiceGuide(res.data));
      }
    }, err => {
      console.log(err);
    })

    !props.how_gomech_works?.length && getHowGomechWorks()
    .subscribe(res => {
      if(res.data){
        props.action(setHowGomechWorks(res.data));
      }
    }, err => {
      console.log(err);
    })

    // !props.gomech_benefits?.length && getGomechanicBenefits()
    // .subscribe(res => {
    //   if(res.data){
    //     props.action(setGomechanicBenefits(res.data));
    //   }
    // }, err => {
    //   console.log(err);
    // })

  },[])

  useEffect(() => {
    const {longitude, latitude, id, sitemap_name: city_name} = props.city || {};
    fetchWorkshopsNearYou({
        latitude, longitude, id
    }
      ).subscribe((res) => {
          if(res.data) {
           setWorkshopData(res.data.data);
          }
        }, (err) => {
          console.error(err);
    })
    getCityWiseRegions(city_name)
    .subscribe(res => {
      if(res.data){
        props.action(setRegionsOfCity(res.data))
      }
    }, err => {
      console.log(err);
    })
  },[props.city]);

  useEffect(() => {
    additionalServiceApi(props.city && props.city.sitemap_name,
      props.cardetails && props.cardetails.fuel && props.cardetails.fuel.car_type_id,
      props.userSelectedCarId && props.userSelectedCarId)
      .subscribe((res) => {
        if(res.data) {
          // console.log("res data",res.data.brands);
          setBrandsData(res.data);
          setAllBrandsData(res.data && res.data.brands && res.data.brands.all_brands && res.data.brands.all_brands);
          setarPartsSection(res.data && res.data.car_parts_section && res.data.car_parts_section.parts);
          setGuaranteeData(res.data &&  res.data.guarantee_data && res.data.guarantee_data.data);
        }
      }, err => {
        console.log(err);
      })
      getHomepageBannerLvl2(props.city && props.city.sitemap_name,
        props.cardetails && props.cardetails.fuel && props.cardetails.fuel.car_type_id,
        props.userSelectedCarId && props.userSelectedCarId, props.city?.latitude, props.city?.longitude)
        .subscribe((res) => {
          if (res.data && Array.isArray(res.data?.mid_banner?.banners)) {
            setBannerLvl2(res.data?.mid_banner?.banners);
          }
        }, err => {
          console.log(err);
        })
  },[props.city, props.cardetails, props.user]);

  const nonLoginLoader = () => (props.user && props.user.is_logged_in ? null : <FullPageLoader />);

  const stringMatch = (input) => input.trim().toLowerCase();

  const getCarPartsSection = (num) => {
    let carParts = [];
    for (let parts of carPartsSection) {
      if (carParts.length >= num) {
        break;
      }
      if (parts.icon) {
          carParts.push(parts);
      }
    }
    // console.log("final data", [...carParts]);
    return [...carParts].filter(function (obj) {
      const quryStr = (key) => {
        let anch = obj.deeplink
        return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
      }
      const str = String(quryStr('pagename'));
      return !(str === 'ACCESSORIES_LIST' || str === 'accessories_list')
    });
  }

  const getGuarnanteeDataParts = (num) => {
    let guaranteesData = [];
    for (let data of guaranteeData) {
      if (guaranteesData.length >= num)
        break;
      if (data.icon)
      guaranteesData.push(data);
    }
    return [...guaranteesData];
  }

  const getSpareParts = (num) => {
    let sparesBrands = [];
    for(let brands of allBrandsData) {
      if(sparesBrands.length >= num)
      break;
      if(brands.img) 
        sparesBrands.push(brands);
    }
    return [...sparesBrands];
  }

  const getFilename = (url) => {
    if(url) {
      return url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."));
    }
    return "";
  }

  const getTopWorkshopsWithImages = (numOfWorkshops) => {
    let workshopsWithImages = [];
    for(let workshop of workshopData){
      if(workshopsWithImages.length >= numOfWorkshops)
        break;
      if(workshop.master_image){
        let updatedUrl = workshop.master_image.replace(/https:\/\/storage\.googleapis\.com\//g, "https://gomechprod.blob.core.windows.net/gomech-crap/");
        let workshopObj = {...workshop, master_image : updatedUrl};
        workshopsWithImages.push(workshopObj);
      }
    }    
    if(workshopData.length >= 4) {
      const lastImage = {
        master_image: "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/locate-us.png", 
        count: workshopData && workshopData.length - 3
      }
      workshopsWithImages = [...workshopsWithImages, lastImage]
    }
    return workshopsWithImages;
  }

  // useEffect(() => {
  //   getServiceBuddyApi(props.city && props.city?.sitemap_name).subscribe((res) => {
  //     if(!!res && res.success == true) {
  //       props.action(serviceBuddyAction(res.data));
  //     }
  //   }, (error) => {
  //     console.error(error);
  //   });
  // },[props.city]);

  let userReviews = [];
  if (props.carModalPage) {
    userReviews = props.accessoriesReviews ? props.accessoriesReviews.map(
      (review) => (
        <UserReviews
          {...review}
          carModalPage
          restrictContent={props.restrictContent}
          workshop={props.workshop}
          whiteBg={!!props.whiteBg}
        />
      ),
    ) : [];
  } else {
    userReviews = props.bikeEnquiry ? props.bikeenquirs.reviews && props.bikeenquirs.reviews?.map((review) => <UserReviews {...review} workshop={props.workshop} />)
      : props.AdvertisePage ? props.home?.youtubeads?.reviews && props.home?.youtubeads?.reviews?.map((review) => <UserReviews {...review} workshop={props.workshop} />) : props.reviewsCurrentCity && props.reviewsCurrentCity.map((review) => <UserReviews {...review} workshop={props.workshop} />);
  }

  // let serviceBuddyReviews = [];
  // serviceBuddyReviews = props.serviceBuddyData && props.serviceBuddyData.map((review) => <ServiceBuddyReviews {...review} workshop={props.workshop} />);


  return (
    <>
      {showPopup ? (
        <Popup
          hide={() => {
            setShowPopup(false);
          }}
        />
      ) : null}

      <>
        {load ? <FullPageLoader /> : null}
      </>

      {/* {props.isPhonePeRequest && nonLoginLoader()} */}
      {!imageLoaded ? <FullPageLoader /> : null }
      
      <div className={s.homepageContainer} onLoad={() => {
        setImageLoaded(true);
      }}>
        {/* <HideOnMobile noParent> */}
          <PcHomeForm loader={setLoad} />
        {/* </HideOnMobile> */}

        <div
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            gridRowStart: 1,
          }}
        >
          <div className={s.PcHomeMainImage}>
            <HomepagePcHeader />
          </div>
        </div>

        <div className={s.HomepageBody}>
          {/* <HideOnMobile noParent> */}
            <PcHomepageNavigationBar />
          {/* </HideOnMobile> */}

          <HomepageSection
            tag="h2"
            title ={`Car Services Available In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}`}
            htmlId={HomepageSections.OUR_SERVICES}
          >
            <div className={s.servicesDescription}>
              <TopContent />
            </div>

            {/* <HideOnMobile> */}
              <div
                className={s.servicesContainer}
                itemScope
                itemType="http://schema.org/SiteNavigationElement"
              >
                {props.servicesTypesList?.filter(item => !item?.is_sos_service)?.map((service) => {
                  // console.log("service data",service);
                  if(service && service.allowCity && service.allowCity.length) {
                    if (!service.allowCity.includes(props.city?.id)) return <React.Fragment key={service.name}></React.Fragment>
                  }
                  return (
                  <Link
                    itemProp="url"
                    onClick={(e) => {
                      e.preventDefault();
                      setLoad(true);  
                      clickCategoryBtnEvent(
                        props?.user?.id,
                        'homepage',
                        service.name,
                        service.id,
                        props?.isAMCUser,
                        props?.cardetails?.model?.id,
                      );                    
                      if(service && service.is_miles === true) {
                        autoUtmHistory(history).push(`/${service.slug}`);
                      }
                      else {
                        props.action(setActiveServiceType(service));
                        autoUtmHistory(history).push(
                          `${generate({
                            city: props.city,
                            subCity: props.selectedRegion,
                            serviceType: service,
                            brand: props.cardetails?.brand,
                            model: props.cardetails?.model,
                            fuel: props.cardetails?.fuel,
                          })}`,
                        );
                        viewItemListGtmEvent(
                          props?.user?.id,
                          service.name,
                          service.id,
                          props?.cardetails?.model?.id,                          
                        );
                      }
                    }}
                    key={service.id}
                    to={service && service.is_miles === true ? service.slug : `${
                      props.apiEndpoints.frontend_serverWithProtocol
                    }${generate({
                      city: props.city,
                      subCity: props.selectedRegion,
                      serviceType: service,
                      brand: props.cardetails?.brand,
                      model: props.cardetails?.model,
                      fuel: props.cardetails?.fuel,
                    })}`}
                    style={{
                      justifySelf: 'center',
                      textDecoration: 'none',
                    }}
                  >
                     <div className={s.desktopNewTagsContainer}>
                      {service.new_tag && <div className={s.newTagsCategory}>{service.new_tag}</div>}
                        <div className={service.new_tag ? classNames(s.newTagServiceItemDesk, s.serviceItem) : s.serviceItem}>
                          <img
                            loading="lazy"
                            src={service.selectedIcon}
                            alt={`${service.name} ${props.city?.name}`}
                          />
                          <span itemProp="name">{service.name}</span>
                        </div>
                    </div>
                  </Link>
                )})}
                {/* <Link to="/accessories">
                  <div className={s.serviceItem}>
                    <img loading="lazy" src="https://via.placeholder.com/90.png?text=Accessories" alt="accessories" />
                    <span itemProp="name">Auto Accessories</span>
                  </div>
                </Link> */}
              </div>
            {/* </HideOnMobile> */}
          </HomepageSection>

          {/* <HomepageSection>
            <InusuranceProgram />
          </HomepageSection> */}
        {props.isMalaysiaRequest ? null :
        (<HomepageSection>
          <div className={s.flexContainer}> 
            <Link
              itemProp="url"
              onClick={(e) => {
                e.stopPropagation();
                autoUtmHistory(history).push('/miles-membership');
                tapMilesEntry({
                  user_id: props.user?.id,
                  fire_screen: 'homepage',
                  car_id: props.cardetails?.fuel?.car_type_id
                })
              }}
              to='/miles-membership'
            >
              <PlaceholderImage
                placeHolderImage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                loading="lazy"
                src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Warranty/miles.png"
                className={s.milesBanner}
                alt="miles"
              />
            </Link>
            {/* <Link
              itemProp="url"
              onClick={(e) => {
                e.stopPropagation();
                autoUtmHistory(history).push('/extended-warranty');
                viewWarrantyPageEvent(props?.user?.id, props?.cardetails?.model?.id);
                tapWarrantyEntry(props?.user?.id, 'homepage', props?.cardetails?.model?.id);
              }}
              to='/extended-warranty'
            >
              <PlaceholderImage
                placeHolderImage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                loading="lazy"
                src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Warranty/warranty.png"
                className={s.milesBanner}
                alt="warranty"
              />
            </Link> */}
            {/* <Link
              itemProp="url"
              onClick={(e) => {
                e.stopPropagation();
                autoUtmHistory(history).push('/accessories');
              }}
              to='/accessories'
            >
              <PlaceholderImage
                placeHolderImage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                loading="lazy"
                src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Warranty/accessories-1.png"
                className={s.milesBanner}
                alt="warranty"
              />
            </Link> */}
          </div>
        </HomepageSection>)}

          <HomepageSection tag="h2" title={brandsData && brandsData.car_parts_section && brandsData.car_parts_section.title} htmlId={HomepageSections.GOMECHANIC_CURATED_CUSTOM_SERVICE}>
              <div className={s.workshopSubHead}>{brandsData && brandsData.car_parts_section && brandsData.car_parts_section.sub_title}</div>
              {getCarPartsSection(3).length < 1 ?
                <>
                  <BottomSlider
                    popularServices={1}
                    content={Array(3).fill().map((_,index) => <CarouselSkeleton keyId={index}/>)}  
                  />
                </>
               : 
               <>
                <BottomSlider
                  scrollButtons
                  arrowContainerStyle={{
                    marginTop: '-42px',
                  }}
                  gridTemplateColumns={{
                    gridTemplateColumns: `repeat(${Math.round(getCarPartsSection().length)},1fr)`,
                    display: 'grid'
                  }}
                  popularServices = {1}
                  content={getCarPartsSection().map((data, index) => (
                    <MonsoonServiceCarousel 
                      keyId={index}
                      carPartsSection={1}
                      articleImage = {data.icon}
                      altTag={data.text}
                      serviceText={data.text}
                      deeplink={data.deeplink}
                      serviceData ={data}
                      tag={data.tag}
                      curated
                    />
                  ))}
                />
               </>
              } 
            </HomepageSection>

          {props.isNigerianRequest ? null : (
            <HomepageSection 
              tag="h2" 
              title={props.home?.monsoonData?.title} 
              htmlId={HomepageSections.GOMECHANIC_MANSOON_SHIELD}
            >
              <div className={s.monsoonSubHead}>{props.home?.monsoonData?.subtitle}</div>
              <MonsoonService />
            </HomepageSection>
          )}
          <HomepageSection
            tag="h2"
            title={''}
          >
          <div style={{marginInline: '-1.25rem', paddingInline: '1.125rem',backgroundColor: '#f5f5f5'}}>
            <GlideCarousel 
              perView={1}
              containerId={`bannerLevel2`} 
              reviews={bannerLvl2?.length > 0 ? bannerLvl2.map((image, index) => <img src={image?.thumbnail} key={index} alt="banner"
              onClick={() => {
                console.log('image',image);
                
                const quryStr = (key) => {
                  let anch = image?.deeplink
                  return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
                }
                const pagename = String(quryStr('pagename'));
                const categoryId = String(quryStr('category_id'));
                const url = String(quryStr('url'));
                if(url.includes('https://ci.policybazaar.com/')){
                  window.location.href = url;
                  return;
                }
                if (pagename) {
                  retailBannerClickEvent(
                    props?.user?.id, 'homepage', null, null, null, index, 'bannerLevel2_mob',
                  );
                }
                if(pagename.includes('AMC_FRAGMENT')){
                  autoUtmHistory(history).push('/miles-membership');
                  return;
                } else if(pagename.includes('services_list')){
                  const serviceType = props.servicesTypesList.find(
                    (item) => item?.id == categoryId
                  ) 
                  props.action(setActiveServiceType(serviceType || null));
                  autoUtmHistory(history).push(`${generate({
                    city: props.city,
                    serviceType: serviceType || null,
                    brand: props.cardetails?.brand,
                    model: props.cardetails?.model,
                    fuel: props.cardetails?.fuel,
                  })}`);
                }
              }}
              
              style={{width: '100%'}}
              />) : (new Array(3).fill({banner_url: ''})).map((data, index) => <CarouselContent
                articleImage={data?.banner_url}
                popularServices={1}
                style={{
                  width: '100%',
                  height: '151px',
                  borderRadius: '6px',
                  backgroundColor: '#fff'
                }}
                keyId={index}
              />)}
            />
          </div>
          </HomepageSection>
          {/* {props.isNigerianRequest || props.isMalaysiaRequest ? null : (
            <HomepageSection tag="h2" title="Choose the Workshop Near You" htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
               <div className={s.workshopSubHead}>{"Schedule a Pickup Today!"}</div>
               {getTopWorkshopsWithImages(3).length < 1 ?
                <>
                  <HideOnMobile>
                    <BottomSlider scrollButtons
                      popularServices={1}
                      content={Array(3).fill(<CarouselSkeleton/>)}     
                    />  
                  </HideOnMobile>
                </>
               : 
               <>
                <HideOnMobile>
                  <BottomSlider
                    scrollButtons
                    popularServices = {1}
                    content={getTopWorkshopsWithImages(3).map((data) => (
                      <WorkShopNearYou 
                        cardetails = {props.cardetails} 
                        articleImage = {data.master_image} 
                        servicesTypesList = {props.servicesTypesList} 
                        popularServices = {1} 
                        workshopData = {workshopData}
                        count = {data.count || 0}
                      />
                    ))}
                  />
                </HideOnMobile>
               </>
              } 
            </HomepageSection> 
          )} */}

          {props.isMalaysiaRequest || getTopWorkshopsWithImages().length < 1 ? null : (
            <HomepageSection tag="h2" title="Choose the Workshop Near You" htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
               <div className={s.workshopSubHead}>{"Schedule a Pickup Today!"}</div>
               {getTopWorkshopsWithImages(3).length < 1 ?
                <>
                    <BottomSlider
                      popularServices={1}
                      content={Array(3).fill().map((_,index) => <CarouselSkeleton keyId={index}/>)}  
                    />  
                </>
               : 
               <>
                  <BottomSlider
                  scrollButtons
                    popularServices = {1}
                    content={getTopWorkshopsWithImages(3).map((data, index) => (
                    <CarouselContent 
                      keyId={index}
                      onClick={e => {setShowWorkshopPopup(true)}}
                      showPopup={showWorkshopPopup} 
                      cardetails = {props.cardetails} 
                      articleImage = {data.master_image}
                      servicesTypesList = {props.servicesTypesList} 
                      popularServices = {1} 
                      count = {data.count || 0}>
                        
                        <WorkShopMapPopup 
                          hide={hidePopup}
                          workshopData={workshopData} 
                          cardetails={props.cardetails} 
                          servicesTypesList={props.servicesTypesList}
                        />
                    </CarouselContent> 
                    ))}
                  />
               </>
              } 
            </HomepageSection> 
          )}

          {/* {props.isNigerianRequest ? null : (
            <HomepageSection tag="h2" title="Introducing GoMechanic Luxe™" htmlId={HomepageSections.GOMECHANIC_LUXE}>
              <HideOnMobile>
                <BottomSlider
                  scrollButtons
                  popularServices={1}
                  content={props.home?.luxeServicesImages.map((data) => (
                    <LuxeService
                      articleImage={data.image}
                      popularServices={1}
                      serviceText={data.heading}
                      serviceTextBottom={data.subHeading}
                      serviceTypeIndex={data.id}
                      luxeServiceBrand={data.brand}
                    />
                  ))}
                />
              </HideOnMobile>
            </HomepageSection>
          )} */}
          {/* {props.isNigerianRequest ? null : (
            <HomepageSection tag="h2" title="Introducing GoMechanic Luxe™" htmlId={HomepageSections.GOMECHANIC_LUXE}>
                <BottomSlider
                  // style={{ marginTop: '-35px' }}
                  scrollButtons
                  popularServices={1}
                  content={props.home?.luxeServicesImages?.map((data) => (
                    <CarouselContent 
                      onClick={(e) => {
                        e.preventDefault();
                        props.action(setActiveServiceType(props.servicesTypesList[0]));
                        props.action(setUserCar({
                          // brand: props.luxeServiceBrand,
                          brand: data.brand,
                          model: null,
                          fuel: null,
                        }));
                        autoUtmHistory(history).push(`${generate({
                          city: props.city,
                          subCity: props.selectedRegion,
                          serviceType: props.servicesTypesList[0],
                          brand: data.brand,
                        })}`, {
                          showCarSelection: true,
                        });
                      }}
                      to={`${generate({
                        city: props.city,
                        subCity: props.selectedRegion,
                        serviceType: props.servicesTypesList[0],
                        brand: data.brand,
                      })}`}
                      articleImage={data.image}
                      popularServices={1}
                      serviceText={data.heading}
                      serviceTextBottom={data.subHeading}
                      serviceTypeIndex={data.id}
                      luxeServiceBrand={data.brand}/>
                  ))}
                />
            </HomepageSection>
          )} */}

          {props.isMalaysiaRequest ? null : (
            <HomepageSection tag="h2" title={brandsData?.guarantee_data?.title} htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
              <div className={s.workshopSubHead}>{brandsData?.guarantee_data?.sub_title}</div>
              {
              getGuarnanteeDataParts(3).length < 1 ?
                <>
                  <BottomSlider
                    popularServices={1}
                    content={Array(3).fill(<CarouselSkeleton />)}
                  />
                </>
                :
                <div className={s.guaranteeContainer}>
                  {getGuarnanteeDataParts().map((data) => (
                    <GoGuranteeSection
                      keyId={data.text}
                      cardetails={props.cardetails}
                      articleImage={data.icon}
                      guaranteeTitle={data.text}
                      bgColor={data.bg_color_code}
                      altTag={data.text}
                    />
                  ))}
                </div>
              }
            </HomepageSection>
          )}

          {props.isNigerianRequest ? (
            <HomepageSection
              tag="h2"
              title="How GoMechanic45 works?"
              htmlId={HomepageSections.HOW_GOMECH_WORKS}
            >
              {/* <HideOnMobile> */}
                <HowGomechWorks />
              {/* </HideOnMobile> */}

            </HomepageSection>
          ) : (
            <HomepageSection
              tag="h2"
              title="How GoMechanic works?"
              htmlId={HomepageSections.HOW_GOMECH_WORKS}
            >
              {/* <HideOnMobile> */}
                <HowGomechWorks />
              {/* </HideOnMobile> */}

            </HomepageSection>
          )}

          {/* {props.isNigerianRequest ? (
            <HomepageSection
              tag="h2"
              title="GoMechanic45 Benefits"
              htmlId={HomepageSections.GOMECH_BENEFITS}
            >
              <div style={{ marginTop: '1.875rem' }}>
                  <GomechBenefitsPC />
              </div>
            </HomepageSection>
          ) : (
            <HomepageSection
              tag="h2"
              title="GoMechanic Benefits"
              htmlId={HomepageSections.GOMECH_BENEFITS}
            >
              <div style={{ marginTop: '1.875rem' }}>
                  <GomechBenefitsPC />
              </div>
            </HomepageSection>
          )} */}

          <div className={s.sectioLine} />
          {/* <HideOnMobileTab> */}
            <RatingReviews style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }} />
          {/* </HideOnMobileTab> */}

          <HomepageSection
            tag="h2"
            title={`What Car Owners In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name} Say`}
            htmlId={HomepageSections.RATING_REVIEWS}
          >
            <div style={{ marginTop: '1.875rem' }}>

              {/* <ReviewsCarousel /> */}
              <GlideCarousel containerId={'reviewCar'} reviews={userReviews}/>

            </div>
          </HomepageSection>
    
          <HomepageSection
            tag="h2"
            title={`Common Car Service Questions In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}`}
            htmlId={HomepageSections.FREQUENTLY_ASKED_QUESTIONS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              <NewFaqAccordion isCityFaq />
            </div>
          </HomepageSection>
          <DisplayWrapper section={displayPossibleSctions.APP_DOWNLOAD}>
            {/* {!props.isPhonePeRequest && !props.isNigerianRequest && !props.isPaytmRequest && ( */}
            <HomepageSection
              title="Download GoMechanic App"
              tag="h2"
              htmlId={HomepageSections.DOWNLOAD_APP}
            >
              <AppDetails />
            </HomepageSection>
          </DisplayWrapper>
          {/* <HomepageSection
            tag="h2"
            title="Featured Service Buddies"
          >
            <ServiceBuddyCarousel />
            <GlideCarousel containerId={'serviceBuddy'} reviews={serviceBuddyReviews}/>
          </HomepageSection> */}

          {props.isMalaysiaRequest ? null : (
            <HomepageSection tag="h2" title={brandsData && brandsData.brands && brandsData.brands.brands_title} htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
              <div className={s.workshopSubHead}>{brandsData && brandsData.brands && brandsData.brands.brands_sub_title}</div>
              {getSpareParts(3).length < 1 ?
                <>
                    <BottomSlider
                      popularServices={1}
                      content={Array(3).fill().map((_,index) => <CarouselSkeleton keyId={index}/>)}  
                    />  
                </>
               : 
               <>
                  <BottomSlider
                    scrollButtons
                    arrowContainerStyle={{
                      marginTop: '-42px',
                    }}
                    popularServices = {1}
                    content={getSpareParts().map((data, index) => (
                    <CarouselContent 
                      keyId={index}
                      showPopup={showWorkshopPopup} 
                      cardetails = {props.cardetails} 
                      articleImage = {data.img}
                      altTag={getFilename(data.img)}
                      servicesTypesList = {props.servicesTypesList} 
                    />
                    ))}
                  />
               </>
              } 
            </HomepageSection> 
          )}

          <DisplayWrapper section={displayPossibleSctions.SERVICE_GUIDE}>
            <HomepageSection
              tag="h2"
              title={`Why Choose GoMechanic In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}?`}
              htmlId={HomepageSections.GOMECH_SERVICE_GUIDE}       
            >
              <div
                style={{
                  paddingBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
              >
                <ServicesGuide tableDataFor="home" />
              </div>
            </HomepageSection>
          </DisplayWrapper>
          {!props.isMalaysiaRequest &&
            <HomepageSection
              tag="h2"
              title={`Car Services price List in ${props.city && props.city?.name}, ${props.isNigerianRequest ? 'Nigeria' : 'India'} ${new Date().getFullYear()}`}
              htmlId={HomepageSections.GOMECH_PRICE_LIST}       
            >
              <div
                style={{
                  paddingBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <PricingTable />
              </div>
            </HomepageSection>
          }
        </div>
      </div>
      {carSelectorShow ? (
        <SelectorMain hide={() => SetCarSelectorShow(false)} />
      ) : null}
      <DisplayWrapper section={displayPossibleSctions.FOOTER}>
        <FooterPage />
      </DisplayWrapper>
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          time={toastConfig.time}
          tickIcon={toastConfig.showTick}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
