import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/Homepage.css';
import schema from './validationSchema';
import Rating from '../Rating/Rating';
import HomepageSection from './HomepageSection/HomepageSection';
import AppDetails from './AppDetails/AppDetails';
import SelectorMain from '../ModalSelectors/SelectorMain';
import ServicesGuide from '../../routes/servicesGuide/ServicesGuide';
import WorksMob from './WorksMob/WorksMob';
import NewFaqAccordion from '../NewFAQ/NewFaqAccordion';
import Link from '../Link';
import { getBrandName, getFuelName, getModelName } from '../../_services/carDetailGetters';
import { fetchWorkshopsNearYou } from '../../_services/api/fetchWorkshopsNearYou';
import { HomepageSections } from '../../constants';
import BottomSlider from '../InsuranceServicesHelpers/BottomSlider/BottomSlider';
import FooterPage from '../FooterPage/FooterPage';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import { setActiveServiceType } from '../../actions/serviceType';
import history from '../../history';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import Toast from '../Toast/Toast';
import Popup from '../EnquiryPopup/Popup';
import MobileMonsoonService from './MonsoonService/MobileMonsoonService';
import DisplayWrapper from '../../_wrappers/DisplayWrapper/DisplayWrapper';
import RatingReviews from './RatingReviews/RatingReviews';
import { createLeads } from '../../_services/api/leads';
import { setLeadAction } from '../../actions/lead';
import autoUtmHistory from '../../_services/autoUtmHistory';
import {
  tapCheckPriceGtmEvent, viewItemListGtmEvent,
  tapMilesEntry, retailSelectCarEvent, retailBannerClickEvent,
  clickCallBtnEvent, clickCategoryBtnEvent,
} from '../../gtm/gtmServices';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import carouselPlaceholderImage from './carouselPlaceholder.png';
import setToastConfigAction from '../../actions/setToastConfig';
import PricingTable from '../../components/PricingTable/PricingTable';
import TopContent from './TopContent';
import CarouselSkeleton from '../Skeleton/CarouselSkeleton';
import CarouselContent from '../CarouselContent/CarouselContent';
import WorkShopMapPopup from './WorkshopMapPopup/workshopMapPopup';
import UserReviews from '../UserReviews/UserReviews';
import GlideCarousel from '../GlideCarousel/GlideCarousel';
import { getCityWiseRegions } from '../../_services/api/getCityWiseRegions';
import { setRegionsOfCity } from '../../actions/regionsAction';
import { getServicesDetails } from '../../_services/api/getServicesDetails';
import { setServicesDetails } from '../../actions/setServicesDetails';
import { getAllServiceGuide } from '../../_services/api/getAllServiceGuide';
import { setServiceGuide } from '../../actions/serviceGuide';
import { getHowGomechWorks } from '../../_services/api/getHowGomechWorks';
import { setHowGomechWorks } from '../../actions/setHowGomechWorks';
import { additionalServiceApi } from '../../_services/api/additionalServiceApi';
import { getHomepageBannerLvl2 } from '../../_services/api/getHomepageBannerLvl2';
import MobileMonsoonServiceCarousel from './MonsoonService/MobileMonsoonServiceCarousel';
import VideoPop from '../Services/VideoSection/VideoPop';
import GoGuranteeSection from './GoGurantee/GoGuranteeSection';
import CitySelectorModal from '../CitySelectorModal/CitySelectorModal';

const MobileHomePage = (props) => {
  useStyles(s);
  const [viewCitySelector, SetView] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [mobile, setMobile] = useState('');
  const [formValid, SetFormValid] = useState(false);
  const [mobileChanged, setMobileChanged] = useState(false);
  const [workshopData, setWorkshopData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [allBrandsData, setAllBrandsData] = useState([]);
  const [carPartsSection, setarPartsSection] = useState([]);
  const [guaranteeData, setGuaranteeData] = useState([]);

  const [carSelectorShow, SetCarSelectorShow] = useState(false);

  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1000,
  });

  const [showPopup, setShowPopup] = useState(false);

  const checkTimeBetweenGivenTimes = (startTime='09:00:00', endTime='19:00:00') => {
    let currentDate = new Date();

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(startTime.split(":")[0]);
    startDate.setMinutes(startTime.split(":")[1]);
    startDate.setSeconds(startTime.split(":")[2]);

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(endTime.split(":")[0]);
    endDate.setMinutes(endTime.split(":")[1]);
    endDate.setSeconds(endTime.split(":")[2]);

    return startDate < currentDate && endDate > currentDate
  }
  const [load, setLoad] = useState(false);
  const [bannerLvl2, setBannerLvl2] = useState([]);

  const { displayPossibleSctions } = props;
  const [showWorkshopPopup, setShowWorkshopPopup] = useState(false);
  const [showVideoPop, setShowVideoPop] = useState({show: false, link: ''});

  const hidePopup = () => {
    setShowWorkshopPopup(false);
    const z = document.getElementsByTagName('body');
    z[0].style.overflow = 'scroll';
  }
  schema
    .isValid({ mobile })
    .then((res) => SetFormValid(res))
    .catch(console.log);

  const handleMobileChange = (event) => {
    if (props.isMalaysiaRequest) {
      if (isNaN(event.target.value) || event.target.value.length > 11) {
        return false;
      }
    } else {
      if (isNaN(event.target.value) || event.target.value.length > 10) {
        return false;
      }
    }
    setMobileChanged(true);
    setMobile(event.target.value);
  };
  useEffect(
    () => {
      if (props.user?.mobile) {
        setMobile(props.user?.mobile);
      }
    },
    [props.user],
  );

  useEffect(() => {
    !Object.keys(props.servicesDetails ?? {}).length && getServicesDetails()
    .subscribe(res => {
      if(res.data){
        props.action(setServicesDetails(res.data));
      }
    }, err => {
      console.log(err);
    })

    !(Object.keys(props.home?.serviceGuide ?? {}).length > 3) && getAllServiceGuide()
    .subscribe(res => {
      if(res.data){
        props.action(setServiceGuide(res.data));
      }
    }, err => {
      console.log(err);
    })

    !props.how_gomech_works?.length && getHowGomechWorks()
    .subscribe(res => {
      if(res.data){
        props.action(setHowGomechWorks(res.data));
      }
    }, err => {
      console.log(err);
    })
  },[])
  useEffect(() => {
    const { longitude, latitude, id, sitemap_name: city_name } = props.city || {};
    fetchWorkshopsNearYou({
      latitude, longitude, id
    }
    ).subscribe((res) => {
      if (res.data) {
        setWorkshopData(res.data.data);
      }
    }, (err) => {
      console.error(err);
    })
    getCityWiseRegions(city_name)
      .subscribe(res => {
        if (res.data) {
          props.action(setRegionsOfCity(res.data))
        }
      }, err => {
        console.log(err);
      })
  }, [props.city]);

  useEffect(() => {
    additionalServiceApi(props.city && props.city.sitemap_name,
      props.cardetails && props.cardetails.fuel && props.cardetails.fuel.car_type_id,
      props.userSelectedCarId && props.userSelectedCarId)
      .subscribe((res) => {
        if (res.data) {
          // console.log("res data",res.data.brands);
          setBrandsData(res.data);
          setAllBrandsData(res.data && res.data.brands && res.data.brands.all_brands && res.data.brands.all_brands);
          setarPartsSection(res.data && res.data.car_parts_section && res.data.car_parts_section.parts);
          setGuaranteeData(res.data &&  res.data.guarantee_data && res.data.guarantee_data.data);
        }
      }, err => {
        console.log(err);
      })
      getHomepageBannerLvl2(props.city && props.city.sitemap_name,
        props.cardetails && props.cardetails.fuel && props.cardetails.fuel.car_type_id,
        props.userSelectedCarId && props.userSelectedCarId, props.city?.latitude, props.city?.longitude)
        .subscribe((res) => {
          if (res.data && Array.isArray(res.data?.mid_banner?.banners)) {
            setBannerLvl2(res.data?.mid_banner?.banners);
          }
        }, err => {
          console.log(err);
        })
  }, [props.city, props.cardetails, props.user]);

  const getSpareParts = (num) => {
    let sparesBrands = [];
    for (let brands of allBrandsData) {
      if (sparesBrands.length >= num)
        break;
      if (brands.img)
        sparesBrands.push(brands);
    }
    return [...sparesBrands];
  }

  const getGuarnanteeDataParts = (num) => {
    let guaranteesData = [];
    for (let data of guaranteeData) {
      if (guaranteesData.length >= num)
        break;
      if (data.icon)
      guaranteesData.push(data);
    }
    return [...guaranteesData];
  }

  const getCarPartsSection = (num) => {
    let carParts = [];
    for (let parts of carPartsSection) {
      if (carParts.length >= num) {
        break;
      }
      if (parts.icon) {
        carParts.push(parts);
      }
    }
    // console.log("final data", [...carParts]);
    return [...carParts].filter(function (obj) {
      const quryStr = (key) => {
        let anch = obj.deeplink
        return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
      }
      const str = String(quryStr('pagename'));
      return !(str === 'ACCESSORIES_LIST' || str === 'accessories_list')
    })
  }

  const getFilename = (url) => {
    if (url) {
      return url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."));
    }
    return "";
  }

  let HomeBannerImages = props.home.cityWiseCarousel[props.city.sitemap_name] || props.home.images;
  const getTopWorkshopsWithImages = (numOfWorkshops) => {
    let workshopsWithImages = [];
    for (let workshop of workshopData) {
      if (workshopsWithImages.length >= numOfWorkshops)
        break;
      if (workshop.master_image){
        let updatedUrl = workshop.master_image.replace(/https:\/\/storage\.googleapis\.com\//g, "https://gomechprod.blob.core.windows.net/gomech-crap/");
        let workshopObj = {...workshop, master_image : updatedUrl};
        workshopsWithImages.push(workshopObj);
      }
    }
    if (workshopData.length >= 4) {
      const lastImage = {
        master_image: "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/locate-us.png",
        count: workshopData && workshopData.length - 3
      }
      workshopsWithImages = [...workshopsWithImages, lastImage]
    }
    return workshopsWithImages;
  }

  let userReviews = [];
  if (props.carModalPage) {
    userReviews = props.accessoriesReviews ? props.accessoriesReviews?.map(
      (review) => (
        <UserReviews
          {...review}
          carModalPage
          restrictContent={props.restrictContent}
          workshop={props.workshop}
          whiteBg={!!props.whiteBg}
        />
      ),
    ) : [];
  } else {
    userReviews = props.bikeEnquiry ? props.bikeenquirs.reviews && props.bikeenquirs.reviews?.map((review) => <UserReviews {...review} workshop={props.workshop} />)
      : props.AdvertisePage ? props.home.youtubeads.reviews && props.home.youtubeads.reviews?.map((review) => <UserReviews {...review} workshop={props.workshop} />) : props.reviewsCurrentCity && props.reviewsCurrentCity.map((review) => <UserReviews {...review} workshop={props.workshop} />);
  }

  const handlerCheckPricesFree = () => {
    try {
      tapCheckPriceGtmEvent(
        props?.user?.id, 'home page',
        props.cardetails.fuel.name,
        props.cardetails.model.name,
        props.cardetails.model.id,
        props.cardetails.brand.name,
        props.cardetails.brand.id,
        mobile,
      );
    } catch (e) {

    }
    let utm_events = {};
    try {
      utm_events=props.initialQueryString?.split('&').map(item => item.split('='))
    } catch(err){
    }
    utm_events = Object.fromEntries(utm_events);
    const pagepath = window ? window.location.href : null;
    let payload = {
      "utm_campaign": utm_events.utm_campaign ?? '',
      "utm_term": utm_events.utm_term ?? '',
      "gclid": utm_events.gclid ?? '',
      "utm_content": utm_events.utm_content ?? '',
      "utm_match": utm_events.utm_match ?? '',
      "utm_keyword": utm_events.utm_keyword ?? '',
      "utm_adgroup": utm_events.utm_adgroup ?? '',
      "utm_medium": utm_events.utm_medium ?? '',
      "utm_source": utm_events.utm_source ?? '',
      "car_type_id": props.cardetails?.fuel?.car_type_id,
      "name": props.user?.name ||'customer',
      "mobile": mobile,
      "location": props.city?.name,
      "channel":"Website",
      "source_id": 15,
      "utm_link": pagepath
    }
    if (props.isMalaysiaRequest) {
      if ((isNaN(mobile) || !(mobile.length == 9 || mobile.length == 10 || mobile.length == 11))) {
        props.action(setToastConfigAction({ show: true, text: 'Please enter your phone number.' }));
        return;
      }
    } else {
      if ((isNaN(mobile) || mobile.length !== 10)) {
        props.action(setToastConfigAction({ show: true, text: 'Please enter your phone number.' }));
        return;
      }
    }
    if (
      props.cardetails.model
      && props.cardetails.brand
      && props.cardetails.fuel
    ) {
      props.action(
        setActiveServiceType(props.servicesTypesList[0]),
      );
      if (!props.lead) {
        if (!isNaN(mobile) && mobile.length === 10) {
          createLeads(payload).subscribe((res) => {
            if (res.status) props.action(setLeadAction(res.data));
          }, console.error);
        }
      }
      setLoad(true);
      autoUtmHistory(history).push(
        generate({
          city: props.city,
          // subCity: props.region,
          subCity: props.selectedRegion || null,
          serviceType: props.servicesTypesList[0],
          brand: props.cardetails.brand,
          model: props.cardetails.model,
          fuel: props.cardetails.fuel,
        }),
      );
    } else {
      setToastConfig({
        ...toastConfig,
        showTick: false,
        show: true,
        text: 'Please select your car.',
      });
    }
  }

  return (
    <>
      {showPopup ? (
        <Popup
          hide={() => {
            setShowPopup(false);
          }}
        />
      ) : null}
      {viewCitySelector ? (
        <CitySelectorModal
          hide={() => SetView(false)}
          accessories={props.accessoriesCar}
        />
      ) : null}
      <>
        {load ? <FullPageLoader /> : null}
      </>

      {!imageLoaded ? <FullPageLoader /> : null}

      <div className={s.homepageContainer} onLoad={() => {
        setImageLoaded(true);
      }}>
        <BottomSlider
            popularServices={1}
            customPadding
            content={HomeBannerImages?.filter((ele) => !ele.includes('is_delete'))?.map((data, index) => (
              <CarouselContent
                articleImage={data}
                popularServices={1}
                style={{
                  width: 'unset',
                  height: '170px',
                  borderRadius: '6px',
                  objectFit: 'unset',
                  // boxShadow: '0.0625rem 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.12)',
                  // backgroundColor: '#fff'
                }}
                keyId={index}
              />
            ))}
          />

        <div className={s.HomepageBody}>

          <h1
            style={{
              margin: '18px 0px 12px',
              fontSize: '1.2rem',
              display: 'flex',
              // justifyContent: 'center',
              textAlign: 'center',
              // marginTop: '10px',
            }}
          >
            {`Experience The Best Car Services In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city.name}`}
          </h1>

          <div
            style={{
              // marginTop: '1.6rem',
              marginBottom: props.isPhonePeRequest ? '14px' : '0',
            }}
          >
            <div
                  className={s.containerBox}
                  style={{
                    marginTop: '14px',
                    marginBottom: '14px',
                  }}
                >
                  <input
                    type="tel"
                    placeholder="ENTER CITY NAME"
                    value={props.city?.name}
                    onClick={() => SetView(true)}
                  />
                  {props.city?.name ?
                     <img
                        loading="lazy"
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/check%20icon.png"
                        alt="check"
                      /> :
                    <div className={s.dotWrap}>
                      <i className={s.dot}></i>
                    </div>
                  }
            </div>
            <div
              className={s.containerBox}
              onClick={() => {
                retailSelectCarEvent(props?.user?.id , 'homepage');
                SetCarSelectorShow(true);
              }}
            >
              <span>
                {props.cardetails.brand
                  && props.cardetails.model
                  && props.cardetails.fuel
                  ? `${getBrandName(props)} ${getModelName(
                    props,
                  )}, ${getFuelName(props)}`
                  : 'SELECT YOUR CAR'}
              </span>
              {props.cardetails.brand
                && props.cardetails.model
                && props.cardetails.fuel ?
                <img
                  loading="lazy"
                  src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/check%20icon.png"
                  alt="check"
                /> :
                (
                  <div className={s.dotWrap}>
                    <i className={s.dot}></i>
                  </div>
                )}
            </div>
               <div
                  className={s.containerBox}
                  style={{
                    marginTop: '14px',
                    marginBottom: '14px',
                  }}
                >
                  <input
                    type="tel"
                    placeholder="ENTER MOBILE NUMBER"
                    value={mobile}
                    onChange={handleMobileChange}
                  />
                  {props.cardetails.brand
                    && props.cardetails.model
                    && props.cardetails.fuel ?
                    (mobile ?
                      <img
                        loading="lazy"
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/check%20icon.png"
                        alt="check"
                      /> : (
                        <div className={s.dotWrap}>
                          <i className={s.dot}></i>
                        </div>)) :
                    null}
                </div>
          </div>
          <div
            onClick={() => {handlerCheckPricesFree()}}
            className={classNames(
              s.containerBox,
              s.redBox,
              formValid ? null : null,
              // s.floater,
            )}
            style={props.isCountry === 'India' || props.isMalaysiaRequest ? null : {
              marginTop: '14px',
              position: 'sticky',
            }}
          >
            <span>CHECK PRICES FOR FREE</span>
          </div>
          <Rating />
          <HomepageSection
            tag="h2"
            title ={`Car Services Available In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}`}
            htmlId={HomepageSections.OUR_SERVICES}
          >
            <div className={s.servicesDescription}>
              <TopContent />
            </div>
              <div
                className={s.categoryContainer}
                itemScope
                itemType="http://schema.org/SiteNavigationElement"
              >
                {props.categoryView && props.categoryView.map((service, index) => {
                  return (
                    <>
                      <div className={s.categroyTitle}>{service.title}</div>
                      <div key={index} className={s.gridCategoryView} style={{ gridTemplateColumns: service.grid ? `repeat(${service.grid},1fr)` : "" }}>
                        {service && service.data.map((item, key) => {
                          // console.log("service items","title",item.image);                    
                          return (
                            <Link
                              className={props.isMalaysiaRequest && item.id === -1 ? s.mlyServiceLinkUrl : s.serviceLinkURL}
                              itemProp="url"
                              onClick={(e) => {
                                e.preventDefault();
                                setLoad(true);
                                clickCategoryBtnEvent(
                                  props?.user?.id,
                                  'homepage',
                                  item.name,
                                  item.id,
                                  props?.isAMCUser,
                                  props?.cardetails?.model?.id,
                                );
                                if (item && item.is_miles === true) {
                                  autoUtmHistory(history).push(`/${item.slug}`);
                                }
                                else {
                                  props.action(setActiveServiceType(item));
                                  autoUtmHistory(history).push(
                                    `${generate({
                                      city: props.city,
                                      subCity: props.selectedRegion,
                                      serviceType: item,
                                      brand: props.cardetails.brand,
                                      model: props.cardetails.model,
                                      fuel: props.cardetails.fuel,
                                    })}`,
                                  );
                                  viewItemListGtmEvent(
                                    props?.user?.id,
                                    item.name,
                                    item.id,
                                    props?.cardetails?.model?.id,                          
                                  );
                                }
                              }
                              }
                              key={item.id}
                              to={(item && item.is_miles === true) ? item.slug : `${props.apiEndpoints.frontend_serverWithProtocol
                                }${generate({
                                  city: props.city,
                                  subCity: props.selectedRegion,
                                  serviceType: item,
                                  brand: props.cardetails.brand,
                                  model: props.cardetails.model,
                                  fuel: props.cardetails.fuel,
                                })}`}
                            >
                              {item.new_tag && <div className={s.newTagsCategory}>{item.new_tag}</div>}
                              <div className={item.new_tag ? classNames(s.newTagsserviceItemMob, s.serviceEleMob) : s.serviceEleMob}>
                                <img
                                  loading="lazy"
                                  src={item.image}
                                  alt={`${item.name} ${props.city.name}`}
                                />
                                <span
                                  className={s.productName}
                                  itemProp="name"
                                >
                                  {item.name}
                                </span>
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </>
                  )
                })}
              </div>
          </HomepageSection>

        {props.isMalaysiaRequest ? null :
          (<HomepageSection>
          <div className={s.flexContainer}> 
            <div 
              onClick={(e) => {
                e.stopPropagation();
                autoUtmHistory(history).push('/miles-membership');
                tapMilesEntry({
                  user_id: props.user?.id,
                  fire_screen: 'homepage',
                  car_id: props.cardetails?.fuel?.car_type_id
                })
              }}
            >
              <PlaceholderImage
                placeHolderImage={carouselPlaceholderImage}
                loading="lazy"
                src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Warranty/miles.png"
                className={s.milesBanner}
                alt="miles"
              />
            </div>
          </div>
          </HomepageSection>)}
          
          {showVideoPop?.show ? (
            <VideoPop 
            videoObj={{video: {link: showVideoPop?.link}}}
            hide={() => {
              setShowVideoPop({show: false, link: ''});
              // setPlayer(true);
              const z = document.getElementsByTagName('body');
              z[0].style.overflow = 'scroll';
            }}
            />
          ) : null }
          
          <HomepageSection
            tag="h2"
            title={''}
          >
          <div style={{marginInline: '-1.25rem', paddingInline: '1.125rem',backgroundColor: '#f5f5f5'}}>
            <GlideCarousel 
              perView={1}
              containerId={`bannerLevel2`} 
              reviews={bannerLvl2?.length > 0 ? bannerLvl2.map((image, index) => <img src={image?.thumbnail} key={index} alt="banner"
              onClick={() => {
                if(image?.video){
                  setShowVideoPop({show: true, link: image?.video});
                  return;
                }

                const quryStr = (key) => {
                  let anch = image?.deeplink
                  return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
                }
                const pagename = String(quryStr('pagename'));
                const categoryId = String(quryStr('category_id'));
                const url = String(quryStr('url'));
                if(url.includes('https://ci.policybazaar.com/')){
                  window.location.href = url;
                  return;
                }
                if (pagename) {
                  retailBannerClickEvent(
                    props?.user?.id, 'homepage', null, null, null, index, 'bannerLevel2_mob',
                  );
                }
                if(pagename.includes('AMC_FRAGMENT')){
                  autoUtmHistory(history).push('/miles-membership');
                  return;
                } else if(pagename.includes('services_list')){
                  const serviceType = props.servicesTypesList.find(
                    (item) => item?.id == categoryId
                  ) 
                  props.action(setActiveServiceType(serviceType || null));
                  autoUtmHistory(history).push(`${generate({
                    city: props.city,
                    serviceType: serviceType || null,
                    brand: props.cardetails?.brand,
                    model: props.cardetails?.model,
                    fuel: props.cardetails?.fuel,
                  })}`);
                }
              }}
              
              style={{width: '100%'}}
              />) : (new Array(3).fill({banner_url: ''})).map((data, index) => <CarouselContent
                articleImage={data?.banner_url}
                popularServices={1}
                style={{
                  width: '100%',
                  height: '151px',
                  borderRadius: '6px',
                  backgroundColor: '#fff'
                }}
                keyId={index}
              />)}
            />
          </div>
          </HomepageSection>
            <HomepageSection tag="h2" title={brandsData && brandsData.car_parts_section && brandsData.car_parts_section.title} htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
              <div className={s.workshopSubHead}>{brandsData && brandsData.car_parts_section && brandsData.car_parts_section.sub_title}</div>
              {getCarPartsSection(3).length < 1 ?
                <>
                  <BottomSlider
                    popularServices={1}
                    content={Array(3).fill(<CarouselSkeleton />)}
                  />
                </>
                :
                <>
                  <div className={s.monsoonIg} style={{gridTemplateColumns: `repeat(${Math.round(getCarPartsSection().length)},1fr)`}}>
                    {getCarPartsSection().filter(function (obj) {
                      const quryStr = (key) => {
                        let anch = obj.deeplink
                        return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
                      }
                      const str = String(quryStr('pagename'));
                      return !(str === 'ACCESSORIES_LIST' || str === 'accessories_list')
                    }).map((data) => (
                      <MobileMonsoonServiceCarousel
                        keyId={data.text}
                        articleImage={data.icon}
                        carPartsSection={1}
                        popularServices={1}
                        serviceText={data.text}
                        deeplink={data.deeplink}
                        serviceData ={data}
                        altTag={data.text}
                        tag={data.tag}
                        curated
                      />
                    ))}
                  </div>
                </>
              }
          </HomepageSection>

          {props.isNigerianRequest ? null : (
            <HomepageSection
              tag="h2"
              title={props.home.monsoonData.title}
              htmlId={HomepageSections.GOMECHANIC_MANSOON_SHIELD}
            >
              <div className={s.monsoonSubHead}>{props.home.monsoonData.subtitle}</div>
              <MobileMonsoonService />
            </HomepageSection>
          )}

          
          {props.isMalaysiaRequest || getTopWorkshopsWithImages().length < 1 ? null : (
            <HomepageSection tag="h2" title="Choose the Workshop Near You" htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
              <div className={s.workshopSubHead}>{"Schedule a Pickup Today!"}</div>
              {getTopWorkshopsWithImages(3).length < 1 ?
                <>
                  <BottomSlider
                    popularServices={1}
                    content={Array(3).fill(<CarouselSkeleton />)}
                  />
                </>
                :
                <>
                  <BottomSlider
                    popularServices={1}
                    content={getTopWorkshopsWithImages(3).map((data) => (
                      <CarouselContent
                        onClick={e => { setShowWorkshopPopup(true) }}
                        showPopup={showWorkshopPopup}
                        cardetails={props.cardetails}
                        articleImage={data.master_image}
                        servicesTypesList={props.servicesTypesList}
                        popularServices={1}
                        count={data.count || 0}>

                        <WorkShopMapPopup
                          hide={hidePopup}
                          workshopData={workshopData}
                          cardetails={props.cardetails}
                          servicesTypesList={props.servicesTypesList}
                        />
                      </CarouselContent>
                    ))}
                  />
                </>
              }
            </HomepageSection>
          )}
          {props.isMalaysiaRequest ? null : (
            <HomepageSection tag="h2" title={brandsData?.guarantee_data?.title} htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
              <div className={s.workshopSubHead}>{brandsData?.guarantee_data?.sub_title}</div>
              {
              getGuarnanteeDataParts(3).length < 1 ?
                <>
                  <BottomSlider
                    popularServices={1}
                    content={Array(3).fill(<CarouselSkeleton />)}
                  />
                </>
                :
                <div className={s.guaranteeContainer}>
                  {getGuarnanteeDataParts().map((data) => (
                    <GoGuranteeSection
                      keyId={data.text}
                      cardetails={props.cardetails}
                      articleImage={data.icon}
                      guaranteeTitle={data.text}
                      bgColor={data.bg_color_code}
                      altTag={data.text}
                    />
                  ))}
                </div>
              }
            </HomepageSection>
          )}

          {props.isNigerianRequest ? (
            <HomepageSection
              tag="h2"
              title="How GoMechanic45 works?"
              htmlId={HomepageSections.HOW_GOMECH_WORKS}
            >

              <WorksMob />
            </HomepageSection>
          ) : (
            <HomepageSection
              tag="h2"
              title="How GoMechanic works?"
              htmlId={HomepageSections.HOW_GOMECH_WORKS}
            >
              <WorksMob />
            </HomepageSection>
          )}

          <div className={s.sectioLine} />

          <div>
            <RatingReviews />
          </div>
          <HomepageSection
            tag="h2"
            title={`What Car Owners In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name} Say`}
            htmlId={HomepageSections.RATING_REVIEWS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              {/* <ReviewsCarousel /> */}
              <GlideCarousel containerId={'reviewCar'} reviews={userReviews} />
            </div>
          </HomepageSection>

          <HomepageSection
            tag="h2"
            title={`Common Car Service Questions In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}`}
            htmlId={HomepageSections.FREQUENTLY_ASKED_QUESTIONS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              <NewFaqAccordion isCityFaq />
            </div>
          </HomepageSection>

          <DisplayWrapper section={displayPossibleSctions.APP_DOWNLOAD}>
            <HomepageSection
              title="Download GoMechanic App"
              tag="h2"
              htmlId={HomepageSections.DOWNLOAD_APP}
            >
              <AppDetails />
            </HomepageSection>
          </DisplayWrapper>

          {props.isMalaysiaRequest ? null : (
            <HomepageSection tag="h2" title={brandsData && brandsData.brands && brandsData.brands.brands_title} htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
              <div className={s.workshopSubHead}>{brandsData && brandsData.brands && brandsData.brands.brands_sub_title}</div>
              {getSpareParts(3).length < 1 ?
                <>
                  <BottomSlider
                    popularServices={1}
                    content={Array(3).fill(<CarouselSkeleton />)}
                  />
                </>
                :
                <>
                  <BottomSlider
                    popularServices={1}
                    content={getSpareParts().map((data) => (
                      <CarouselContent
                        showPopup={showWorkshopPopup}
                        style={{ width: "130px", height: "auto" }}
                        cardetails={props.cardetails}
                        articleImage={data.img}
                        altTag={getFilename(data.img)}
                        servicesTypesList={props.servicesTypesList}
                      />
                    ))}
                  />
                </>
              }
            </HomepageSection>
          )}

          <DisplayWrapper section={displayPossibleSctions.SERVICE_GUIDE}>
            <HomepageSection
              tag="h2"
              title={`Why Choose GoMechanic In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}?`}
              htmlId={HomepageSections.GOMECH_SERVICE_GUIDE}       
            >
              <div
                style={{
                  paddingBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
              >
                <ServicesGuide tableDataFor="home" />
              </div>
            </HomepageSection>
          </DisplayWrapper>
          {!props.isMalaysiaRequest &&
            <HomepageSection
              tag="h2"
              title={`Car Services price List in ${props.city && props.city?.name}, ${props.isNigerianRequest ? 'Nigeria' : 'India'} ${new Date().getFullYear()}`}
              htmlId={HomepageSections.GOMECH_PRICE_LIST}       
            >
              <div
                style={{
                  paddingBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <PricingTable />
              </div>
            </HomepageSection>
          }
        </div>
      </div>
      {carSelectorShow ? (
        <SelectorMain hide={() => SetCarSelectorShow(false)} />
      ) : null}
      <DisplayWrapper section={displayPossibleSctions.FOOTER}>
        <FooterPage />
      </DisplayWrapper>
      {!props.isMalaysiaRequest && checkTimeBetweenGivenTimes() && 
        <div
          className={s.callMe}
          onClick={() => {
            clickCallBtnEvent(props?.user?.id, props.cardetails?.model?.id, 'homepage', props.isAMCUser)
          }}
        >
          <a href="tel:9388893888">
            <img src={'https://gomechanic.in/assets/img/advertisepage/Icons/phone-new.png'} alt='call us'/>
          </a>
        </div>
      }
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          time={toastConfig.time}
          tickIcon={toastConfig.showTick}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHomePage);
